import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Box, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';

export const LoginJsx = (props) => {
return (
<div className={`login`}>
  <Grid container spacing={0}>
    <Grid item xs={6} className='left-col'>
    </Grid>
    <Grid item xs={6} className='right-col'>
    <form className={`form-container ${props.fadedLeft ? 'faded-left' : 'not-faded'} ${props.fadedRight ? 'faded-right' : 'not-faded'}`} onSubmit={(e) => props.handleSubmit(e)}>
      <div className='logo-round'></div>
      <Box m={2.369}>
      <Typography variant="h5" className="form-title">
      Welcome Back
      </Typography>
      </Box>
        <Box m={1} className='form-group'>
          <InputLabel className='form-label'>Email</InputLabel>
          <TextField variant="outlined" size="small" id="loginEmail" className='full-width' type='text' onKeyUp={(e) => props.setFormValues(e)}/>
        </Box>
        <Box m={1} className='form-group'>
          <InputLabel className='form-label'>Password</InputLabel>
          <TextField variant="outlined" size="small" id="loginPassword" className='full-width' type='password' onKeyUp={(e) => props.setFormValues(e)}/>
        </Box>
        <Box m={2.369} display="flex" flexDirection="center">
        {
        !props.isLoading && <Button variant="contained" align="center" className='btn-theme' type='submit'>Sign In</Button>
        }
        {
        props.isLoading && <CircularProgress className="centered" />
        }
        </Box>
        <Link className='form-link turquoise-font' to="/forgot-password">Forgot Your Password?</Link>
        <Link className='form-link turquoise-font' to="/register">Register Now</Link>

        {<InputLabel className='error-label form-link'>{props.loginErr}</InputLabel>}
      </form>
      </Grid>
  </Grid>
</div>
)
}

export default LoginJsx;